import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueCookies from 'vue-cookies';
import smart from 'address-smart-parse';
import installElementPlus from './plugins/element';
import * as ElementPlusIconsVue from '@element-plus/icons-vue';
import axios from 'axios';
import ModalHelper from './utils/modalHelper';
import VueClipBoard from 'vue-clipboard2'
const app = createApp(App);
import dir from './directive/index';
app.directive('myOneClick', dir.my_one_click);
app.directive('myInput', dir.myInput);
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
	app.component(key, component);
}
installElementPlus(app);
let baseURL = '/api';
if (process.env.NODE_ENV !== "development") {
    baseURL = ''
}

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$cookies = VueCookies;
app.config.globalProperties.$smart = smart;
app.config.globalProperties.$modalHelper = ModalHelper;
app.config.globalProperties.exportUrl = 'https://' + window.location.host;
// app.config.globalProperties.exportUrl = "http://testjzdccrm.yuluojishu.com"
app
	.use(store)
	.use(router)
	.use(VueClipBoard)
	.mixin({
		data() {
			return { baseURL };
		},
	})
	.mount('#app');
window.addEventListener('storage', function (e) {
	if (e.key != 'token') return;
	if (localStorage.getItem('token')) {
	} else {
		let lybl = '';
		lybl = router.resolve({
			path: '/',
			query: {
				name: 'home',
			},
		});
		window.open(lybl.href, '_self');
	}
});
