import { createRouter, createWebHistory } from 'vue-router';
const routes = [
	// 首页
	{
		path: '/',
		name: 'home',
		component: () => import('../views/HomeView.vue'),
	},
	// 客服端
	{
		path: '/service',
		name: 'service',
		component: () => import('../views/serviceView.vue'),
		children: [
			// 控制面板
			{
				path: '',
				component: () => import('../components/Service/ControlView.vue'),
			},
			{
				path: 'teach',
				component: () => import('../components/Service/teacherView.vue'),
			},
			// 实收业绩
			{
				path: 'Actual',
				component: () => import('../components/Performance/ActualPerformance.vue'),
			},
			// 违约金业绩
			{
				path: 'LiquidatedDamages',
				component: () => import('../components/Performance/damagesPerformance.vue'),
			},
			// 账号设置
			{
				path: 'setting',
				component: () => import('../components/Service/SettingView.vue'),
			},
			// 线索列表跟进详情
			{
				path: 'management',
				component: () => import('../components/Thousand/ManagementView.vue'),
			},
			//线索管理--线索列表
			{
				path: 'clues',
				component: () => import('../components/LeadManagement/CluesView.vue'),
			},
			// 审批管理
			{
				path: 'leadapproval',
				component: () => import('../components/LeadManagement/LeadApproval.vue'),
			},

			// 交易列表
			{
				path: 'trade',
				component: () => import('../components/LeadManagement/TransactionView.vue'),
			},
			//交易认领
			{
				path: 'claim',
				component: () => import('../components/LeadManagement/ClaimView.vue'),
			},
			//学员列表
			{
				path: 'traineelist',
				component: () => import('../components/LeadManagement/traineelistView.vue'),
			},
			// 分期数据
			{
				path: 'stagingData',
				component: () => import('../components/LeadManagement/StagingDataView.vue'),
			},
			// 分期数据查看详情
			{
				path: 'ViewDetails/:id/:tab/:thread_id?/:source_id',
				component: () => import('../components/LeadManagement/ViewDetails.vue'),
			},
			// 补缴详情
			{
				path: 'Supplementary/:id/:name',
				component: () => import('../components/LeadManagement/components/StagingDataElement/Supplementary.vue'),
			},
			// 分期数据--退学详情--详情
			{
				path: 'DropOutDetails/:id/:detail_type',
				component: () => import('../components/LeadManagement/components/StagingDataElement/DropOutDetails.vue'),
			},
			// 退学列表
			{
				path: 'DropOut',
				component: () => import('../components/LeadManagement/DropOutView.vue'),
			},
			// 催收列表
			{
				path: 'Collection',
				component: () => import('../components/LeadManagement/Collection.vue'),
			},
			{
				path: 'Collecdetail/:id/:thread_id',
				component: () => import('../components/LeadManagement/components/CollectionElement/detail.vue'),
			},
			// 阶段交易列表
			{
				path: 'StageTransactionList',
				component: () => import('../components/LeadManagement/StageTransactionView.vue'),
			},
			// 分阶段学员
			{
				path: 'PhasedTraine',
				component: () => import('../components/LeadManagement/PhasedTraine.vue'),
			},
			// 分阶段学员详情
			{
				path: 'Phasedtrainee/:id',
				component: () => import('../components/LeadManagement/Phasedtrainee.vue'),
			},
			// 申请审核列表
			{
				path: 'leadapproval',
				component: () => import('../components/LeadManagement/LeadApproval.vue'),
			},
			// 审批管理详情
			{
				path: 'leadapprovaldetail/:id',
				component: () => import('../components/LeadManagement/components/LeadApprovalElement/LeadApprovalDetail.vue'),
			},
			// 论坛管理--匿名回答
			{
				path: 'anonymity',
				component: () => import('../components/Forum/anonymityView.vue'),
			},
			// 匿名回答详情页
			{
				path: 'anonymitdetail/:id?',
				component: () => import('../components/Forum/anonymitdetailView.vue'),
			},
			// 问题管理--
			{
				path: 'problemManage',
				component: () => import('../components/Forum/ProblemManage.vue'),
			},
			// 问题新增
			{
				path: 'problemNew/:id?',
				component: () => import('../components/Forum/ProblemNew.vue'),
			},
			// 问题列表详情
			{
				path: 'problemDetail/:id?/:isAnswer?',
				component: () => import('../components/Forum/ProblemDetail.vue'),
			},
			//教学管理--报名学员管理
			{
				path: 'NewStudentSmanage',
				component: () => import('../components/TeachingManagement/Studentsmanage.vue'),
			},
			// 教学学员管理
			{
				path: 'Teachingmanage',
				component: () => import('../components/TeachingManagement/Teachingmanage.vue'),
			},
			// 教学学员管理(跟进====)
			{
				path: 'StudentInfo',
				component: () => import('../components/TeachingManagement/StudentInfo.vue'),
			},
			// 导入直播数据
			{
				path: 'LiveBroadcast',
				component: () => import('../components/TeachingManagement/components/TeachingElement/LiveBroadcast.vue'),
			},
			// 教学学员管理详情
			{
				path: 'Ordersdetail/:id?',
				component: () => import('../components/TeachingManagement/components/TeachingElement/Ordersdetail.vue'),
			},
			// 教学审批管理
			{
				path: 'Approvalmanage',
				component: () => import('../components/TeachingManagement/Approvalmanage.vue'),
			},
			// 学期管理
			// {
			//     path: 'semester',
			//     component: () => import('../components/TeachingManagement/Semestermanage.vue'),
			// },
			// 发布考试
			// {
			//     path: 'Examination/:id',
			//     component: () => import('../components/TeachingManagement/components/SemesterElement/Examination.vue'),
			// },
			// 添加学员
			// {
			//     path: 'Viewsemester',
			//     component: () => import('../components/TeachingManagement/components/SemesterElement/Viewsemester.vue'),
			// },
			// 发布作业
			// {
			//     path: 'homework/:id',
			//     component: () => import('../components/TeachingManagement/components/SemesterElement/homework.vue'),
			// },
			// 发布承诺书1
			// {
			//     path: 'release/:id',
			//     component: () => import('../components/TeachingManagement/components/SemesterElement/release.vue'),
			// },
			// 试卷资源管理
			{
				path: 'PaperSche',
				component: () => import('../components/TeachingManagement/PaperSche.vue'),
			},
			// 新建试卷
			{
				path: 'NewPaper/:id?',
				component: () => import('../components/TeachingManagement/components/TestpaperElement/NewPaper.vue'),
			},
			// 批阅管理
			{
				path: 'Marking',
				component: () => import('../components/TeachingManagement/Reviewmanagement.vue'),
			},
			// 批阅作业
			{
				path: 'Assignview/:id',
				component: () => import('../components/TeachingManagement/components/ReviewElement/Assignview.vue'),
			},
			// 批阅考试
			{
				path: 'Paperview/:id',
				component: () => import('../components/TeachingManagement/components/ReviewElement/Paperview.vue'),
			},
			// 集训实战营
			// {
			//     path: 'TrainingCamp',
			//     component: () => import('../components/TeachingManagement/TrainingCamp.vue'),
			// },
			// 集训实战营 查看课表
			// {
			//     path: 'TrainingCampViewCourse/:course_id/:m_course_id/:e_course_id',
			//     component: () => import('../components/TeachingManagement/components/TrainingCampElement/TrainingCampViewCourse.vue'),
			// },
			// 集训实战营新增讲次
			// {
			//     path: 'TrainingCampAddLecture/:course_id/:m_course_id/:e_course_id',
			//     component: () => import('../components/TeachingManagement/components/TrainingCampElement/TrainingCampAddLecture.vue'),
			// },
			// 讲师课后评价
			{
				path: 'teachEvaluate',
				component: () => import('../components/TeachingManagement/teachEvaluateView.vue'),
			},
			// 评价详情
			{
				path: 'teachParticulars',
				component: () => import('../components/TeachingManagement/components/TeachParticularsElement/teachParticulars.vue'),
			},
			// 财务管理--退款审核
			{
				path: 'Refund',
				component: () => import('../components/Financial/Financial.vue'),
			},
			// 审核管理--审核列表
			{
				path: 'auditlist',
				component: () => import('../components/AuditManagement/AuditList.vue'),
			},
			// 投诉管理--客诉投诉
			{
				path: 'CustomerComplaints',
				component: () => import('../components/complaints/CustomerComplaints.vue'),
			},
			// 数据统计1--考勤数据
			{
				path: 'CheckAttendance',
				component: () => import('../components/DataStatistics/CheckAttendance.vue'),
			},
			// 异动数据
			{
				path: 'Different',
				component: () => import('../components/DataStatistics/Different.vue'),
			},
			// 数据统计2--考勤数据
			{
				path: 'anomaly',
				component: () => import('../components/DataStatistics/anomaly.vue'),
			},
			// 台账管理--台账数据
			{
				path: 'LedgerData',
				component: () => import('../components/LedgerManagement/LedgerData.vue'),
			},
			// 教学管理--课程管理
			{
				path: 'courseManage',
				component: () => import('../components/coursePages/courseManage.vue'),
			},
			// 课表管理
			{
				path: 'Schedule',
				component: () => import('../components/coursePages/Schedule.vue'),
			},
			// 新建课程
			{
				path: 'newconStruction',
				component: () => import('../components/coursePages/newconStruction.vue'),
			},
			// 编辑课程
			{
				path: 'Editingcourse',
				component: () => import('../components/coursePages/Editingcourse.vue'),
			},
			// 新建课表
			{
				path: 'newSchedule',
				component: () => import('../components/coursePages/newSchedule.vue'),
			},
			// 查看课表
			{
				path: 'ViewSchedule/:id/:stage_info',
				component: () => import('../components/coursePages/ViewSchedule.vue'),
			},
			// 关联视频
			{
				path: 'Relevancyrcy/:id',
				component: () => import('../components/coursePages/Relevancy.vue'),
			},
			// 生成课表
			{
				path: 'generateSche/:list',
				component: () => import('../components/coursePages/generateSchedule.vue'),
			},
			// 报名学员管理
			// {
			//     path: 'NewStudentSmanage',
			//     component: () => import('../components/coursePages/NewStudentSmanage.vue'),
			// },
			// 教学学员管理
			// {
			//     path: 'TeachingManage',
			//     component: () => import('../components/coursePages/TeachingManage.vue'),
			// },
			// 导入直播数据
			// {
			//     path: 'LiveBroadcast',
			//     component: () => import('../components/coursePages/components/TeachingManageElement/LiveBroadcast.vue')
			// },
			// 教学审批管理
			{
				path: 'ApprovalManage',
				component: () => import('../components/coursePages/ApprovalManage.vue'),
			},
			// 视频资源管理
			{
				path: 'VideoResources',
				component: () => import('../components/coursePages/VideoResources.vue'),
			},
			// // 题库资源管理
			// {
			//     path: 'QuestionBank',
			//     component: () => import('../components/coursePages/QuestionBank.vue'),
			// },
			// // 新建题库
			// {
			//     path: 'newQuestionb/:id/:type?',
			//     component: () => import('../components/coursePages/components/QuestionBankElement/newQuestionb.vue'),
			// },
			// 试卷资源
			{
				path: 'PaperSche',
				component: () => import('../components/coursePages/PaperSche.vue'),
			},
			// 新建试卷
			{
				path: 'NewPaper/:id?',
				component: () => import('../components/coursePages/components/PaperScheElement/NewPaper.vue'),
			},
			// 集训实战营
			{
				path: 'TrainingCamp',
				component: () => import('../components/coursePages/TrainingCamp.vue'),
			},
			// 集训实战营 查看课表
			{
				path: 'TrainingCampViewCourse/:course_id/:m_course_id/:e_course_id',
				component: () => import('../components/coursePages/components/TrainingCampElement/TrainingCampViewCourse.vue'),
			},
			// 集训实战营新增讲次
			{
				path: 'TrainingCampAddLecture/:course_id/:m_course_id/:e_course_id',
				component: () => import('../components/coursePages/components/TrainingCampElement/TrainingCampAddLecture.vue'),
			},
			// 学期管理
			{
				path: 'semester',
				component: () => import('../components/Paper/semester.vue'),
			},
			// 发布承诺书
			{
				path: 'release/:id',
				component: () => import('../components/Paper/Release.vue'),
			},
			// 添加学员
			{
				path: 'Viewsemester',
				component: () => import('../components/Paper/Viewsemester.vue'),
			},
			// 发布作业
			{
				path: 'homework/:id',
				component: () => import('../components/Paper/homework.vue'),
			},
			// 发布考试
			{
				path: 'Examination/:id',
				component: () => import('../components/Paper/Examination.vue'),
			},
			// 批阅管理
			{
				path: 'Marking',
				component: () => import('../components/Marking/Schedule.vue'),
			},
			// 批阅作业
			{
				path: 'Assignview/:id',
				component: () => import('../components/Marking/Assignview.vue'),
			},
			// 批阅考试
			{
				path: 'Paperview/:id',
				component: () => import('../components/Marking/Paperview.vue'),
			},
			// 课程付费--课程
			{
				path: 'coursePay',
				component: () => import('../components/CoursePayment/coursePayment.vue'),
			},
			// 课程付费--订单
			{
				path: 'courseOrders',
				component: () => import('../components/CoursePayment/orders.vue'),
			},
			// 兼职管理 -- 兼职列表
			{
				path: 'parttimeList',
				component: () => import('../components/parTimeManstatus/parttimeList.vue'),
			},
			// 兼职管理 -- 兼职学员列表
			{
				path: 'partStutentsList',
				component: () => import('../components/parTimeManstatus/partStutentsList.vue'),
			},
			// 学员兼职打卡详情
			{
				path: 'thirdThreeDetile/:id/:thread_id',
				component: () => import('../components/parTimeManstatus/components/thirdThreeDetile.vue'),
			},
			// 报名用户 查看详情
			{
				path: 'registeredUsersDetails/:id/:is_processed/:is_cancel/:is_unqualified/:is_can_pay/:is_pay',
				component: () => import('../components/PartTimeManagement/registeredUsersDetails.vue'),
			},
		],
	},
	{
		path: '/404',
		name: '404',
		component: () => import('../views/404.vue'),
	},
	{
		path: '/:pathMatch(.*)',
		redirect: '/404',
	},
];

const router = createRouter({
	history: createWebHistory(process.env.BASE_URL),
	routes,
});

export default router;
